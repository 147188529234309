import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
    <Layout>
        <SEO title="Home"/>
        <div className="mt-24">
            <h2 className="text-3xl md:text-4xl font-bold uppercase mb-4 text-white">Analyse & Konzept.<br /> Umsetzung <span className="lowercase">zum</span> Erfolg.</h2>
            <p className="text-lg text-gray md:text-2xl">Ein nachhaltiges Geschäftsmodell aufbauen & Unternehmensabläufe optimieren. Von der Produktentwicklung bis zum erfolgreichen Verkauf.<br /> Wir freuen uns auf Ihre <a href="mailto:office@depretis.gmbh
" className="text-white hover:text-opacity-70 transition duration-300">Kontaktaufnahme</a>.</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-12">
            <div className="col-span-1 font-nunito bg-white px-7 py-10">
                <h3 className="font-bold uppercase text-black border-dotted border border-t-0 border-r-0 border-l-0 border-gray-light mb-4 pb-3">Unternehmens&shy;beratung & Organisations&shy;entwicklung</h3>
                <ul className="text-gray">
                    <li>. Produktmanagement</li>
                    <li>. Produktentwicklung</li>
                    <li>. Supply Chain Management</li>
                </ul>
            </div>
            <div className="col-span-1 font-nunito bg-white px-7 py-10">
                <h3 className="font-bold uppercase text-black border-dotted border border-t-0 border-r-0 border-l-0 border-gray-light mb-4 pb-3">&nbsp;<br className="hidden md:block"/>Handelsagentur</h3>
                <ul className="text-gray">
                    <li>. Internationaler Vertrieb</li>
                    <li>. Key Account Betreuung</li>
                </ul>
            </div>
            <div className="mt-12 text-lg text-gray">
                <h2 className="text-xl font-bold uppercase text-white">Daniel de Pretis</h2>
                <p className="mb-4">Unternehmensberatung & Handelsagentur</p>

                <p>+43 664 4418335<br/>
                    office@depretis.gmbh<br/>
                    depretis.gmbh</p>
            </div>
        </div>
    </Layout>
)

export default IndexPage
